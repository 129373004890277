
















import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { EventbusType, EventPayload, IEventbus } from '@movecloser/front-core'
import { AbstractSelectControlOption } from '@movecloser/ui-core'

import { Inject } from '../../../../../extensions'
import { isLink, log } from '../../../../../support'

import {
  departmentsListColumnsConfig,
  jobModelsListColumnsConfig,
  locationsListColumnsConfig, optionsListColumnsDefaultConfig,
  OptionsListColumnsDefinition,
  OptionsListItem
} from '../../../../molecules/OptionsList'
import { SearchOptionType } from '../../../../molecules/Search/Search.config'

import { SearchTileDefinition } from '../../../SearchResults'

import { ResolvedSearchAddon } from './Search.contracts'

/**
 * @see Search.formData
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
interface FormData {
  text: string | null
}

/**
 * UI component for the `HeroModuleAddonType.Search`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<SearchAddonUi>({
  name: 'SearchAddonUi',
  components: {
    OptionsList: () => import(
      /* webpackChunkName: "molecules/OptionsList" */
      '../../../../molecules/OptionsList/OptionsList.vue'
    ),
    Search: () => import(
      /* webpackChunkName: "molecules/Search" */
      '../../../../molecules/Search/Search.vue'
    )
  },
  created () {
    this.buildInitOptions()
    this.handleQueryOptions()
  },
  mounted () {
    this.eventBus.handle('app:remove-search-tile', (event: EventPayload<SearchTileDefinition | null>) => {
      if (!event.payload) {
        this.selectedOptions = []
      } else {
        this.selectedOptions = this.selectedOptions.filter((option) => option.value !== event.payload?.value)
      }
    })
  }
})
export class SearchAddonUi extends Vue {
  @Inject(EventbusType)
  private readonly eventBus!: IEventbus

  /**
   * Array of departments to be rendered as an `<option>` elements for the "department" `<select>` field.
   */
  @Prop({ type: Array, required: true })
  public readonly departments!: ResolvedSearchAddon['departments']

  /**
   * Array of locations to be rendered as an `<option>` elements for the "location" `<select>` field.
   */
  @Prop({ type: Array, required: true })
  public readonly locations!: ResolvedSearchAddon['locations']

  /**
   * Array of jobsModels to be rendered as an `<option>` elements for the "jobsModels" `<select>` field.
   */
  @Prop({ type: Array, required: true })
  public readonly jobsModels!: ResolvedSearchAddon['jobsModels']

  /**
   * @see ResolvedSearchAddon.searchResultsPage
   */
  @Prop({ type: Object, required: true })
  public readonly searchResultsPage!: ResolvedSearchAddon['searchResultsPage']

  @Prop({ type: Boolean, required: false, default: false })
  public withGallery!: boolean

  @Ref('searchAddon')
  public readonly searchAddonRef!: Vue

  @Ref('optionsList')
  public readonly optionsListRef!: HTMLElement

  /**
   * Currently opened filter context
   */
  public openedOption: string = ''

  public offset: number = 0
  public offsetLeft: number = 0

  public departmentsOptions: Array<OptionsListItem> = []
  public locationsOptions: Array<OptionsListItem> = []
  public jobsModelsOptions: Array<OptionsListItem> = []

  /**
   * All selected options under currently opened filter
   */
  public selectedOptions: Array<OptionsListItem> = []

  public get actualOffsetLeft (): number {
    return this.offsetLeft
  }

  public get columnsConfig (): OptionsListColumnsDefinition {
    switch (this.openedOption) {
      case SearchOptionType.Departments:
        return departmentsListColumnsConfig
      case SearchOptionType.Locations:
        return locationsListColumnsConfig
      case SearchOptionType.JobModel:
        return jobModelsListColumnsConfig
      default:
        return optionsListColumnsDefaultConfig
    }
  }

  public get optionsList (): Array<OptionsListItem> {
    switch (this.openedOption) {
      case SearchOptionType.Departments:
        return this.departmentsOptions
      case SearchOptionType.Locations:
        return this.locationsOptions
      case SearchOptionType.JobModel:
        return this.jobsModelsOptions
      default:
        return []
    }
  }

  public get optionsWrapperStyles (): Record<string, string> {
    return {
      transform: `translateX(${this.actualOffsetLeft}px)`,
      opacity: this.actualOffsetLeft > 0 ? '1' : this.actualOffsetLeft < 0 ? '1' : '0'
    }
  }

  /**
   * Builds option items along with their nested children.
   * E.g. Parent value: 'it', Child value: 'it-architecture'
   */
  public buildItems (list: Array<AbstractSelectControlOption>, type: string): Array<OptionsListItem> {
    let output: Array<OptionsListItem> = []
    const toBeRemoved: Array<Omit<OptionsListItem, 'children'>> = []

    for (let i = 0; i < list.length; i++) {
      output.push({
        type,
        label: list[i].label as string,
        value: list[i].value as string
      })

      for (let j = i + 1; j < list.length - 1; j++) {
        const prefix = (list[j].value as string).split('-')[0]
        if (output.find((item) => item.value === prefix)) {
          const newNestedChild = {
            type,
            label: list[j].label as string,
            value: list[j].value as string
          }

          output = output
            .map((outputItem) => {
              if (outputItem.value === prefix) {
                return outputItem.children && !!outputItem.children.find(child => child.value === newNestedChild.value)
                  ? outputItem
                  : {
                    ...outputItem,
                    children: [
                      ...(outputItem.children ?? []),
                      newNestedChild
                    ]
                  }
              }

              return outputItem
            })

          toBeRemoved.push({
            type,
            label: list[j].label as string,
            value: list[j].value as string
          })
        } else {
          break
        }
      }
    }

    return output.filter((item) => !toBeRemoved.map((removed) => removed.value).includes(item.value))
  }

  /**
   * Opens selected filter options component
   * @param type
   */
  public handleOpenOptions (type: string): void {
    this.openedOption = this.openedOption === type ? '' : type
  }

  public setOptionsListOffset (offset: number): void {
    this.offset = offset
    this.onOffsetChange()
  }

  private onOffsetChange (): void {
    if (this.offset === 0 || !this.optionsListRef) {
      return
    }

    setTimeout(() => {
      const listWidth = this.optionsListRef.getBoundingClientRect().width
      this.offsetLeft = this.offset - (listWidth / 2)
    }, 0)
  }

  /**
   * Updates selected options.
   * Note: Clicking parent option checks also its children.
   * @param item
   */
  public updateSelected (item: OptionsListItem): void {
    if (this.selectedOptions.find((selectedItem) => selectedItem.value === item.value)) {
      if (!!item.children && item.children.length > 0) {
        this.selectedOptions = this.selectedOptions
          .filter((selectedItem) => selectedItem.value !== item.value)

        // Filter all available children of unselected parent
        const availableChildren = item.children.filter((child) => !child.label.includes('(0)'))
        for (const child of availableChildren) {
          this.selectedOptions = this.selectedOptions.filter((selectedItem) => selectedItem.value !== child.value)
        }
      }
      this.selectedOptions = this.selectedOptions.filter((selectedItem) => selectedItem.value !== item.value)
    } else {
      if (!!item.children && item.children.length > 0) {
        this.selectedOptions.push(item)

        // Push each available child if parent selected
        const availableChildren = item.children.filter((child) => !child.label.includes('(0)'))
        for (const child of availableChildren) {
          this.selectedOptions.push(child)
        }
      } else {
        this.selectedOptions.push(item)
      }
    }

    this.selectedOptions = this.selectedOptions.map((option) => {
      return {
        ...option,
        label: this.rawChildrenLabel(option)
      }
    })
  }

  /**
   * Returns element label without fixed `└` sign at the beginning
   */
  public rawChildrenLabel (item: OptionsListItem): string {
    const elements = item.label.split(' ')
    return elements[0] === '└' ? elements.slice(1, elements.length).join(' ') : item.label
  }

  /**
   * Handles the `@submit` event on the `<Search>` component.
   */
  public onSubmit (formData: FormData): void {
    try {
      this.goToSearchResultsPage(formData)
      this.openedOption = ''
    } catch (error) {
      const message: string = 'SearchAddonUi.onSubmit(): Failed to redirect to the search results page!'
      log([message, error], 'error')
    }
  }

  /**
   * Determines whether the component has got all it needs for a successful render.
   */
  public get shouldRender (): boolean {
    return this.hasDepartments && this.hasLocations && this.hasSearchResultsPage
  }

  /**
   * Determines whether the component has been provided with the correct `departments` @Prop.
   */
  private get hasDepartments (): boolean {
    return typeof this.departments === 'object' &&
      Array.isArray(this.departments) &&
      this.departments.length > 0
  }

  /**
   * Determines whether the component has been provided with the correct `locations` @Prop.
   */
  private get hasLocations (): boolean {
    return typeof this.locations === 'object' &&
      Array.isArray(this.locations) &&
      this.locations.length > 0
  }

  /**
   * Determines whether the component has been provided with the correct `searchResultsPage` @Prop.
   */
  private get hasSearchResultsPage (): boolean {
    return isLink(this.searchResultsPage)
  }

  /**
   * Redirects the User to the search results page.
   *
   * @param formData - Data entered by the User inside the `<Search>` component.
   */
  private goToSearchResultsPage (formData: FormData): void {
    const { text } = formData

    const selectedDepartments = this.selectedOptions
      .filter((option) => option.type === SearchOptionType.Departments)
      .map((option) => option.value)
      .join(',')

    const selectedLocations = this.selectedOptions
      .filter((option) => option.type === SearchOptionType.Locations)
      .map((option) => option.value)
      .join(',')

    const searchedJobModels = this.selectedOptions
      .filter((option) => option.type === SearchOptionType.JobModel)
      .map((option) => option.value)
      .join(',')

    const searchResultsPage = this.$router.resolve(this.searchResultsPage.target)

    this.$router.push({
      ...searchResultsPage.location,
      query: { d: selectedDepartments, l: selectedLocations, m: searchedJobModels, q: text }
    })
  }

  private handleQueryOptions (): void {
    const query = this.$route.query
    if (!query) {
      return
    }

    this.handleQueryDepartments()
    this.handleQueryLocations()
    this.handleQueryJobModels()
  }

  private buildInitOptions (): void {
    this.departmentsOptions = this.buildItems(this.departments, SearchOptionType.Departments)
    this.locationsOptions = this.buildItems(this.locations, SearchOptionType.Locations)
    this.jobsModelsOptions = this.jobsModels ? this.buildItems(this.jobsModels, SearchOptionType.JobModel) : []
  }

  /**
   * Loads departments by query
   * @private
   */
  private handleQueryDepartments (): void {
    const query = this.$route.query
    const d = query.d
    const currentDepartments = d ? (d as string).split(',') : ''

    this.selectedOptions = [
      ...this.selectedOptions,
      ...(currentDepartments.length > 0 ? this.departmentsOptions.filter((option) => currentDepartments.includes(option.value)) : [])
    ]
  }

  /**
   * Loads locations from query
   * @private
   */
  private handleQueryLocations (): void {
    const query = this.$route.query
    const l = query.l
    const currentLocations = l ? (l as string).split(',') : ''

    this.selectedOptions = [
      ...this.selectedOptions,
      ...(currentLocations.length > 0 ? this.locationsOptions.filter((option) => currentLocations.includes(option.value)) : []),
    ]
  }

  /**
   * Loads jobModels from query
   * @private
   */
  private handleQueryJobModels (): void {
    const query = this.$route.query
    const m = query.m
    const currentJobModels = m ? (m as string).split(',') : ''

    this.selectedOptions = [
      ...this.selectedOptions,
      ...(currentJobModels.length > 0 ? this.jobsModelsOptions.filter((option) => currentJobModels.includes(option.value)) : []),
    ]
  }

  @Watch('$router')
  private onRouteUpdate () {
    console.log(this.$route)
  }
}

export default SearchAddonUi
